<template>
  <div class="home">
    <div class='center'>
      <img alt="logo" v-if="img_url" :src="img_url">
      <img alt="logo" v-else src="../assets/a.png">
      <van-field type="textarea" rows="3" autosize v-model="img_url" label-width="60">
        <template #button>
          <van-button size="mini" type="primary" @click="getimg">重新获取</van-button>
        </template>
      </van-field>
      <div class="list">
        <van-radio-group v-model="imgsIndex" direction="horizontal" @change="change">
          <van-radio :name="val.id" v-for="val in imgsList" :key="val.id">{{ val.name }}</van-radio>
        </van-radio-group>
      </div>

      <van-field type="textarea" label="内容" rows="3" autosize v-model="message" label-width="60" />

      <van-field name="switch" label="是否@所有人">
        <template #right-icon>
          <van-switch v-model="all_item" size="20" />
        </template>
      </van-field>
      <div class="list" v-if="!all_item">
        <van-checkbox-group v-model="result" direction="horizontal">
          <van-checkbox :name="item.tel" :disabled='item.disabled' v-for="item in list" clickable :key="item.tel">
            {{ item.name }}
          </van-checkbox>
          <router-link to="add" class="item">新增成员</router-link>
        </van-checkbox-group>
      </div>

      <van-button class="button" round block type="info" native-type="submit" :loading='loading'
        @click="pushdata">提交</van-button>
    </div>
  </div>
</template>

<script>
  import axios from "axios"
  export default {
    name: "Home",
    data() {
      return {
        imgsIndex: 1,//默认黑丝
        imgsList: [
          {
            id: 1,
            name: "黑丝",
            api: 'https://v2.api-m.com/api/heisi?return=json'
          },
          {
            id: 2,
            name: "白丝",
            api: 'https://v2.api-m.com/api/baisi?return=json'
          },
          {
            id: 3,
            name: "女",
            api: 'https://v2.api-m.com/api/meinvpic?return=json'
          }
        ],

        message: "石墨:项目进度 项目:git上传",
        img_url: "",

        all_item: true,
        api: "/api/robot/send?access_token=2f56ed23786060a28dd64cd4226ecd7b426b9b2de65ac5abdf45de192991a183",
        loading: false,
        result: [],

        list: [],
      }
    },
    created() {
      if (localStorage.getItem('pushUserlist')) {
        this.list = JSON.parse(localStorage.getItem('pushUserlist'))
      } else {
        this.list = [
          {
            name: "王凯鹏",
            tel: "15065627623",
          },
          {
            name: "宫政",
            tel: "13563678626",
          },
        ]
        localStorage.setItem('pushUserlist', JSON.stringify(this.list))
      }
      // this.getimg()
    },
    methods: {

      getimg() {
        const data = this.imgsList.find(item => item.id == this.imgsIndex)
        axios({
          url: data.api,
          method: "get",
          headers: { "Content-Type": "application/json;charset=UTF-8" },
        }).then((res) => {
          this.$toast.clear()
          if (res.data.code == 200) {
            this.img_url = res.data.data
          }
        }).catch(() => { this.$toast.clear() })
      },
      change() {
        this.$toast.loading({
          message: '加载中...',
          loadingType: 'spinner',
          duration: 0,
        });
        this.getimg()
      },
      pushdata() {
        this.loading = true
        let atMobiles = []
        let content = ""
        if (this.all_item) {
          this.list.forEach((item) => {
            atMobiles.push(item.tel)
            content += `@${item.tel} `
          })
        } else {
          this.list.forEach((item) => {
            this.result.forEach((val) => {
              if (item.id == val) {
                atMobiles.push(item.tel)
                content += `@${item.tel} `
              }
            })
          })
        }
        let data = {
          msgtype: "markdown",
          markdown: {
            title: "任务",
            text: `### 每日任务\n ![图片](${this.img_url}) \n\n##### ${this.message} \n\n${content ? content : ""}`,
          },
          at: { isAtAll: false, atMobiles, },
        }
        axios({
          url: process.env.NODE_ENV == "development" ? "/api" : this.api,
          method: "post",
          data,
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        }).then((res) => {
          if (res.data.errmsg == "ok") {
            this.$toast({
              message: "推送成功",
              onClose: () => {
                this.loading = false
              },
            })
          } else {
            this.$toast({
              message: res.data.errmsg,
              onClose: () => {
                this.loading = false
              },
            })
          }
        }).catch((err) => {
          this.$toast("推送失败")
          this.loading = false
        })
      },
    },
  }
</script>
<style scoped>
  .home {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
  }
  .center {
    width: 100%;
    img {
      width: 240px;
      height: 260px;
      object-fit: contain;
    }
  }
  /deep/.van-cell {
    width: 100%;
    margin-top: 10px;
    border: 1px solid #eee;
    border-radius: 10px;
  }
  .list {
    width: 100%;
    margin-top: 10px;
    border: 1px solid #eee;
    border-radius: 10px;
  }
  /deep/.van-radio {
    margin: 20px 10px;
  }
  /deep/.van-checkbox {
    margin: 20px 10px;
  }
  .item {
    margin: 20px 10px;
  }
  .button {
    margin-top: 20px;
  }
</style>
